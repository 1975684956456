var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"col":"9","align":"start","align-self":"center"}},[_c('v-btn-toggle',{attrs:{"dense":"","color":"primary","group":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},[_c('v-btn',{attrs:{"value":"BuscarAportes","disabled":_vm.buscarAportesTabActivate},on:{"click":function($event){return _vm.switchTab(1)}}},[_vm._v(" Buscar aportes esperados vigentes ")]),_c('v-btn',{attrs:{"value":"importarAportes","disabled":_vm.itemsNuevaActualizacionAportes.length > 0},on:{"click":function($event){return _vm.switchTab(2)}}},[_vm._v(" Procesar archivo ")])],1)],1),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"1","align-self":"center","align":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","align":"left"}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 pt-5",attrs:{"cols":"6","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Período","outlined":"","dense":"","clearable":"","autocomplete":"not","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(_vm.rules.periodoYyyyMm),"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1),_c('v-col',{staticClass:"py-0 pt-8 ",attrs:{"cols":"4","md":"4","sm":"12"}},[_c('p',{staticClass:"grey--text py-0"},[_vm._v(" Porcentaje de actualización para aplicar ")])]),_c('v-col',{staticClass:"pb-0 pt-7",attrs:{"cols":"3"}},[_c('currency-input',{attrs:{"label":"Valor","appendIcon":_vm.porcentajeIcon,"rules":_vm.rules.required.concat(
                    _vm.rules.nonZero,
                    _vm.porcentaje > 0
                      ? _vm.rules.maxLength(_vm.porcentaje, 5)
                      : _vm.rules.maxLength(_vm.porcentaje, 6)
                  ),"options":_vm.percentOptions},model:{value:(_vm.porcentaje),callback:function ($$v) {_vm.porcentaje=$$v},expression:"porcentaje"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pb-0 pt-5 text-right",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsNuevaActualizacionAportes,"loading":_vm.isLoading,"show-select":"","sort-by":"apellidoNombre","item-key":"idAporteEsperado","search":_vm.search},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"item.tipoYDoc",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(_vm._s(item.tipoDoc)+" - "+_vm._s(item.docId)+" ")])]}},{key:"item.data-table-select",fn:function(ref){
                  var item = ref.item;
                  var isSelected = ref.isSelected;
                  var select = ref.select;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"value":isSelected,"readonly":item.hasError,"disabled":item.hasError,"color":"primary","ripple":false},on:{"input":function($event){return select($event)}}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[(item.hasError)?_c('span',[_vm._v("Registro con error. No se puede seleccionar")]):_c('span',[_vm._v("Seleccionar registro")])])]}},{key:"item.grupo",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.grupo))])]}},{key:"item.apellidoNombre",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.apellidoNombre)+" ")])]}},{key:"item.importe",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(_vm.addDecimals(item.importe))+" ")])]}},{key:"item.periodoDesde",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.periodoDesde)+" ")])]}},{key:"item.periodoHasta",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.periodoHasta)+" ")])]}},{key:"item.importeNuevo",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(_vm.addDecimals(item.importeNuevo))+" ")])]}},{key:"item.periodoDesdeNuevo",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.periodoDesdeNuevo)+" ")])]}},{key:"item.periodoHastaNuevo",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(item.hasError ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.periodoHastaNuevo)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3","align":"end"}},[_c('v-btn',{staticClass:"no-upper-case to-right",attrs:{"color":"primary","small":"","loading":_vm.loadingDeleteAllBtn,"disabled":_vm.itemsNuevaActualizacionAportes.length === 0 ||
                    _vm.loadingDeleteAllBtn},on:{"click":function($event){return _vm.deleteAllAportes()}},model:{value:(_vm.deleteAllItems),callback:function ($$v) {_vm.deleteAllItems=$$v},expression:"deleteAllItems"}},[_vm._v(" Eliminar todos ")])],1)],1)],1)]},proxy:true},{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"fontColor",staticStyle:{"text-align":"center"},attrs:{"colspan":"3"}},[_vm._v(" Datos del titular ")]),_c('th',{staticStyle:{"text-align":"right"},attrs:{"colspan":""}},[_vm._v("|")]),_c('th',{staticClass:"fontColor",staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v(" Aporte esperado actual ")]),_c('th',{staticStyle:{"text-align":"right"},attrs:{"colspan":""}},[_vm._v("|")]),_c('th',{staticClass:"fontColor",staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v(" Nuevo aporte esperado ")]),_c('th',{staticClass:"left",staticStyle:{"text-align":"right"},attrs:{"colspan":""}},[_vm._v("|")])])])]},proxy:true}],null,true),model:{value:(_vm.registrosSeleccionados),callback:function ($$v) {_vm.registrosSeleccionados=$$v},expression:"registrosSeleccionados"}})],1),_c('v-card-actions',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"m-2 p-2 to-right fontsize",attrs:{"color":"primary","align":"end","outlined":"","disabled":_vm.itemsNuevaActualizacionAportes.length == 0 ||
              _vm.itemsActualizacionGenerada.length > 0},on:{"click":_vm.exportDetalle}},[_vm._v(" Exportar detalle ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"m-2 py-4 p-2 fontsize",attrs:{"color":"primary","disabled":_vm.itemsNuevaActualizacionAportes.length == 0 ||
              _vm.itemsActualizacionGenerada.length > 0,"outlined":""},on:{"click":_vm.exportErrores}},[_vm._v(" Exportar errores ")])],1),_c('v-col',[_c('v-btn',{staticClass:"m-2 p-2",attrs:{"disabled":_vm.registrosSeleccionados.length == 0 ||
              _vm.itemsActualizacionGenerada.length > 0,"color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.generarNuevaActualizacion()}}},[_vm._v(" Guardar ")])],1)],1)],1),(_vm.modalImportar)?_c('v-dialog',{attrs:{"max-width":"60%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalImportar($event)}},model:{value:(_vm.modalImportar),callback:function ($$v) {_vm.modalImportar=$$v},expression:"modalImportar"}},[_c('ImportarAportesEsperados',{attrs:{"itemsNuevaActualizacionAportes":_vm.itemsNuevaActualizacionAportes},on:{"disableFilters":_vm.disableFilters,"toggleModalImportar":_vm.toggleModalImportar}})],1):_vm._e(),(_vm.modalExportarExcel)?_c('v-dialog',{attrs:{"max-width":"46%","persistent":""},model:{value:(_vm.modalExportarExcel),callback:function ($$v) {_vm.modalExportarExcel=$$v},expression:"modalExportarExcel"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v("Se generará un archivo con el detalle de los comprobantes")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v(" Continuar ")])],1)],1)],1)],1):_vm._e(),_c('DeleteDialog',{attrs:{"maxWidth":'30%',"titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){_vm.itemsNuevaActualizacionAportes = [];
      _vm.registrosSeleccionados = [];}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }