<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
      <v-container class="pt-0">
        <v-row justify="end">
          <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
            <v-btn-toggle dense v-model="text" color="primary" group>
              <v-btn
                value="BuscarAportes"
                @click="switchTab(1)"
                :disabled="buscarAportesTabActivate"
              >
                Buscar aportes esperados vigentes
              </v-btn>
              <v-btn
                value="importarAportes"
                @click="switchTab(2)"
                :disabled="itemsNuevaActualizacionAportes.length > 0"
              >
                Procesar archivo
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="1" align-self="center" align="center" class="pr-0 pl-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="showFilters = !showFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="left" class="pt-0 pb-0">
            <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
          </v-col>
        </v-row>
        <v-expand-transition mode="out-in">
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="applyFilters()"
          >
            <v-row>
              <v-col cols="6" md="4" sm="12" class="py-0 pt-5">
                <!-- Período -->
                <v-text-field
                  v-model="periodo"
                  label="Período"
                  outlined
                  dense
                  clearable
                  autocomplete="not"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  :rules="rules.required.concat(rules.periodoYyyyMm)"
                  hint="Formato AAAAMM"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <!-- Porcentaje -->
              <v-col cols="4" md="4" sm="12" class="py-0 pt-8 " >
                <p class="grey--text py-0">
                  Porcentaje de actualización para aplicar
                </p>
              </v-col>
              <v-col cols="3" class="pb-0 pt-7" >
                <currency-input
                  v-model="porcentaje"
                  label="Valor"
                  :appendIcon="porcentajeIcon"
                  :rules="
                    rules.required.concat(
                      rules.nonZero,
                      porcentaje > 0
                        ? rules.maxLength(porcentaje, 5)
                        : rules.maxLength(porcentaje, 6)
                    )
                  "
                  :options="percentOptions"
                ></currency-input>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      size="28"
                      @click="resetForm"
                    >
                      {{ clearFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>Limpiar filtros</span>
                </v-tooltip>
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  elevation="2"
                  small
                  type="submit"
                  form="filters-form"
                >
                  Aplicar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsNuevaActualizacionAportes"
        :loading="isLoading"
        show-select
        sort-by="apellidoNombre"
        v-model="registrosSeleccionados"
        item-key="idAporteEsperado"
        class="elevation-1"
        :search="search"
        @toggle-select-all="selectAllToggle"
      >
        <template v-slot:[`item.tipoYDoc`]="{ item }"
          ><span :style="item.hasError ? 'color:red' : ''"
            >{{ item.tipoDoc }} - {{ item.docId }}
          </span></template
        >
        <template
          v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.hasError"
                :disabled="item.hasError"
                v-bind="attrs"
                v-on="on"
                color="primary"
                @input="select($event)"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
            <span v-if="item.hasError"
              >Registro con error. No se puede seleccionar</span
            >
            <span v-else>Seleccionar registro</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.grupo`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ item.grupo }}</span
          >
        </template>
        <template v-slot:[`item.apellidoNombre`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ item.apellidoNombre }}
          </span>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ addDecimals(item.importe) }}
          </span>
        </template>
        <template v-slot:[`item.periodoDesde`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ item.periodoDesde }}
          </span>
        </template>
        <template v-slot:[`item.periodoHasta`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ item.periodoHasta }}
          </span>
        </template>
        <template v-slot:[`item.importeNuevo`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ addDecimals(item.importeNuevo) }}
          </span>
        </template>
        <template v-slot:[`item.periodoDesdeNuevo`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ item.periodoDesdeNuevo }}
          </span>
        </template>
        <template v-slot:[`item.periodoHastaNuevo`]="{ item }">
          <span :style="item.hasError ? 'color:red' : ''">
            {{ item.periodoHastaNuevo }}
          </span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3" align="end">
                <v-btn
                  color="primary"
                  small
                  class="no-upper-case to-right"
                  v-model="deleteAllItems"
                  :loading="loadingDeleteAllBtn"
                  @click="deleteAllAportes()"
                  :disabled="
                    itemsNuevaActualizacionAportes.length === 0 ||
                      loadingDeleteAllBtn
                  "
                >
                  Eliminar todos
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`header`]="">
          <thead>
            <tr>
              <th colspan="3" class="fontColor" style="text-align: center">
                Datos del titular
              </th>
              <th colspan="" style="text-align: right">|</th>
              <th colspan="2" class="fontColor" style="text-align: center">
                Aporte esperado actual
              </th>
              <th colspan="" style="text-align: right">|</th>
              <th colspan="2" class="fontColor" style="text-align: center">
                Nuevo aporte esperado
              </th>
              <th colspan="" style="text-align: right" class="left">|</th>
            </tr>
          </thead>
        </template>
      </v-data-table>
    </v-card>
    <v-card-actions>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="8">
          <v-btn
            color="primary"
            class="m-2 p-2 to-right fontsize"
            align="end"
            outlined
            :disabled="
              itemsNuevaActualizacionAportes.length == 0 ||
                itemsActualizacionGenerada.length > 0
            "
            @click="exportDetalle"
          >
            Exportar detalle
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            color="primary"
            class="m-2 py-4 p-2 fontsize"
            :disabled="
              itemsNuevaActualizacionAportes.length == 0 ||
                itemsActualizacionGenerada.length > 0
            "
            outlined
            @click="exportErrores"
          >
            Exportar errores
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            :disabled="
              registrosSeleccionados.length == 0 ||
                itemsActualizacionGenerada.length > 0
            "
            class="m-2 p-2"
            color="primary"
            @click="generarNuevaActualizacion()"
            :loading="isLoading"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-dialog
      v-if="modalImportar"
      v-model="modalImportar"
      max-width="60%"
      persistent
      @keydown.esc="toggleModalImportar"
    >
      <ImportarAportesEsperados
        :itemsNuevaActualizacionAportes="itemsNuevaActualizacionAportes"
        @disableFilters="disableFilters"
        @toggleModalImportar="toggleModalImportar"
      ></ImportarAportesEsperados>
    </v-dialog>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="46%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los comprobantes</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="to-right" text @click="exportExcel()">
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <DeleteDialog
      :maxWidth="'30%'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="
        itemsNuevaActualizacionAportes = [];
        registrosSeleccionados = [];
      "
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import ImportarAportesEsperados from "@/views/modules/cuotas/devengamientos/ImportarAportesEsperados.vue";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  name: "NuevaActualizacionAportesEsperados",
  components: {
    PageHeader,
    GoBackBtn,
    FiltersSelected,
    DeleteDialog,
    ImportarAportesEsperados,
    Ayuda,
    CurrencyInput
  },
  directives: { mask },
  data() {
    return {
      title: enums.titles.NUEVA_ACTUALIZACION_AP_ESPERADO,
      porcentajeIcon: enums.icons.PERCENT_OUTLINE,
      anularIcon: enums.icons.CIRCLE_NONE,
      checkbox: enums.icons.CHECK_BOX,
      seeIcon: enums.icons.SEE,
      canAnularNuevo: false,
      routeToGo: "ActualizacionAportesEsperados",
      text: "BuscarAportes",
      textConfirmDialog: "",
      openConfirmDialog: false,
      periodo: "",
      percentOptions: {
        locale: "es-ES",
        currency: "USD",
        currencyDisplay: "hidden",
        valueRange: {
          min: -99.99,
          max: 10000
        },
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: false,
        accountingSign: false
      },
      porcentaje: null,
      tabBuscarAportes: true,
      tabImportar: false,
      modalExportarExcel: false,
      itemsNuevaActualizacionAportes: [],
      filtersApplied: [],
      itemsActualizacionGenerada: [],
      showExpand: false,
      showHelp: false,
      optionCode: enums.webSiteOptions.APORTES_ESPERADOS,
      showIcon: true,
      showFilters: true,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      isFormValid: false,
      isLoading: false,
      search: "",
      searchActualizacion: "",
      registrosSeleccionados: [],
      searchIcon: enums.icons.SEARCH,
      loadingDeleteAllBtn: false,
      titleDelete: null,
      deleteAllItems: false,
      showDeleteModal: false,
      fileNameArchivo: null,
      headers: [
        {
          text: "N° grupo familiar",
          value: "grupo",
          sortable: false,
          align: "start"
        },
        {
          text: "Tipo y N° de documento",
          value: "tipoYDoc",
          sortable: false,
          align: "start"
        },
        {
          text: "Apellido y nombre",
          value: "apellidoNombre",
          sortable: false,
          align: "start"
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end"
        },
        {
          text: "Período desde",
          value: "periodoDesde",
          sortable: false
        },
        {
          text: "Período hasta",
          value: "periodoHasta",
          sortable: false
        },
        {
          text: "Importe",
          value: "importeNuevo",
          sortable: false,
          align: "end"
        },
        {
          text: "Período desde",
          value: "periodoDesdeNuevo",
          sortable: false
        },
        {
          text: "Período hasta",
          value: "periodoHastaNuevo",
          sortable: false
        }
      ],
      headersActualizacion: [
        {
          text: "N° de grupo familiar",
          value: "grupo",
          sortable: false
        },
        {
          text: "N° de documento",
          value: "docId",
          sortable: false
        },
        {
          text: "Apellido y nombre",
          value: "apellidoNombre",
          sortable: false
        },
        {
          text: "Importe",
          align: "end",
          value: "importeNuevo",
          sortable: false
        },
        {
          text: "Período desde",
          value: "periodoDesdeNuevo",
          sortable: false
        },
        {
          text: "Período hasta",
          value: "periodoHastaNuevo",
          sortable: false
        },
        {
          text: "Observación",
          value: "mensajeError",
          sortable: false
        }
      ],

      rules: rules,
      modalImportar: false,
      session: null,
      check: true,
      buscarAportesTabActivate: true,
      nuevasActualizaConErrores: [],
      openModalVerActualizacion: false,
      nuevasActualizaSinError: [],
      disabledCount: 0
    };
  },
  created() {
    if (this.$route.params.canAnularNuevo) {
      this.canAnularNuevo = true;
    }
  },
  methods: {
    ...mapActions({
      anularProceso: "configuracion/anularProceso",
      grabarAumentoMasivoAporteEsperado:
        "configuracion/grabarAumentoMasivoAporteEsperado",
      fetchListaProcesoAporteEsperadoAumentoByParams:
        "configuracion/fetchListaProcesoAporteEsperadoAumentoByParams",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      setAlert: "user/setAlert"
    }),
    selectAllToggle() {
      if (
        this.registrosSeleccionados.length !=
        this.itemsNuevaActualizacionAportes.length - this.disabledCount
      ) {
        this.registrosSeleccionados = [];
        this.itemsNuevaActualizacionAportes.forEach(item => {
          if (!item.hasError) {
            this.registrosSeleccionados.push(item);
          }
        });
        this.setAlert({
          type: "info",
          message: "Todos los registros sin errores han sido seleccionados."
        });
      } else if (
        this.itemsNuevaActualizacionAportes.length == this.disabledCount
      ) {
        this.registrosSeleccionados = [];
        this.setAlert({
          type: "info",
          message:
            "No se puede seleccionar ningún registro porque poseen errores."
        });
      } else {
        this.registrosSeleccionados = [];
        this.setAlert({
          type: "info",
          message: "Todos los registros han sido deseleccionados."
        });
      }
    },
    exportDetalle() {
      let result = [];
      this.itemsNuevaActualizacionAportes?.forEach(x =>
        result.push({
          ["N° de grupo familiar"]: x.grupo,
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.docId,
          ["Apellido y nombre"]: x.apellidoNombre,
          ["Importe-aporte esperado actual"]: x.importe,
          ["Aporte esperado actual-desde"]: x.periodoDesde,
          ["Aporte esperado actual-hasta"]: x.periodoHasta,
          ["Importe-nuevo aporte esperado"]: x.importeNuevo,
          ["Nuevo aporte esperado-desde"]: x.periodoDesdeNuevo,
          ["Nuevo aporte esperado-hasta"]: x.periodoHastaNuevo
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado lista"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle de nueva actualización de aportes esperados"
      );
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },

    switchTab(num) {
      this.itemsNuevaActualizacionAportes = [];
      this.resetForm();
      this.filtersApplied = [];
      if (this.showFilters === false) this.showFilters = true;
      switch (num) {
        case 1:
          this.tabBuscarAportes = true;
          this.buscarAportesTabActivate = true;
          this.modalImportar = false;
          break;
        case 2:
          this.tabBuscarAportes = false;
          this.modalImportar = true;
          this.buscarAportesTabActivate = false;
          this.tabImportar = false;
          break;
        default:
          break;
      }
    },
    resetForm() {
      if (this.itemsNuevaActualizacionAportes.length === 0) this.periodo = "";
      this.porcentaje = "";
    },
    async applyFilters() {
      this.disabledCount = 0;
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const filters = {
        periodo: this.periodo,
        porcentaje: parseFloat(this.porcentaje)
      };
      const response = await this.fetchListaProcesoAporteEsperadoAumentoByParams(
        filters
      );
      this.itemsNuevaActualizacionAportes = response.data.data;
      this.itemsNuevaActualizacionAportes.map(item => {
        if (item.hasError) this.disabledCount += 1;
      });
      this.selectAllToggle();
      this.nuevasActualizaConErrores = this.itemsNuevaActualizacionAportes.filter(
        x => x.hasError > 0
      );
      this.isLoading = false;
    },
    exportErrores() {
      let result = [];
      if (this.nuevasActualizaConErrores.length === 0)
        return this.setAlert({
          type: "warning",
          message: "No existen aportes con errores."
        });
      this.nuevasActualizaConErrores?.forEach(x =>
        result.push({
          ["N° de grupo familiar"]: x.grupo,
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.docId,
          ["Apellido y nombre"]: x.apellidoNombre,
          ["Aporte esperado actual-importe"]: new Intl.NumberFormat(
            "de-DE"
          ).format(x.importe),
          ["Mensaje de error"]: x.mensajeError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Listado de errores");
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.periodo) {
        this.filtersApplied.splice(0, 1, {
          key: "periodo",
          label: "Aportes esperados vigentes al ",
          model: this.periodo
        });
      }
      if (this.porcentaje) {
        this.filtersApplied.splice(1, 0, {
          key: "porcentaje",
          label: "Porcentaje de actualización para aplicar",
          model: this.porcentaje
        });
      }
    },
    deleteAllAportes() {
      this.deleteAllItems = true;
      this.titleDelete = "¿Quitar todos los aportes?";
      this.showDeleteModal = true;
    },
    disableFilters(nombreArchivo) {
      // Aprovecho esta funcion para setear el nombre del archivo pero no tiene nada que ver con el metodo
      this.fileNameArchivo = nombreArchivo;
      this.showFilters = false;
      this.selectAllToggle();
    },
    // toggles
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    toggleModalImportar() {
      this.modalImportar = !this.modalImportar;
      this.text = "BuscarAportes";
      this.tabBuscarAportes = true;
      this.buscarAportesTabActivate = true;
    },
    toggleModalExportarExcel() {
      this.modalExportarExcel = !this.modalExportarExcel;
    },
    async generarNuevaActualizacion() {
      this.isLoading = true;
      const data = {
        nombreArchivo: this.fileNameArchivo,
        listaAporteEsperadoParamGrabar: this.registrosSeleccionados,
        periodo: this.periodo,
        porcentaje: this.porcentaje ? parseFloat(this.porcentaje) : 0
      };
      const res = await this.grabarAumentoMasivoAporteEsperado(data);
      if (res.status === 200) {
        this.setAlert({
          type: "success",
          message: "Actualización realizada con éxito"
        });
        this.itemsActualizacionGenerada = res.data.data.aportes;
        this.itemsNuevaActualizacionAportes = [];
        // ACA SE EMITE EL METODO QUE ABRE EL DETALLE DEL PROCESO
        this.$router.push({
          name: "ActualizacionAportesEsperados",
          params: { procIdNuevo: res.data.data.procId }
        });
      }
      this.isLoading = false;
    },
    closeModal() {
      this.$router.push({ name: "ActualizacionAportesEsperados" });
    },

    exportActualizacionGenerada() {
      if (this.itemsActualizacionGenerada.length === 0)
        return this.setAlert({
          type: "warning",
          message: "No hay datos para exportar"
        });
      let result = [];
      this.itemsActualizacionGenerada?.forEach(x =>
        result.push({
          ["N° de grupo"]: x.grupo,
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.docId,
          ["Apellido y nombre"]: x.apellidoNombre,
          ["Aporte esp. actual-Importe"]: new Intl.NumberFormat("de-DE").format(
            x.importe
          ),
          ["Aporte esp. actual-Desde"]: x.periodoDesde,
          ["Aporte esp. actual-Hasta"]: x.periodoHasta,
          ["Nuevo aporte esp. -importe"]: new Intl.NumberFormat("de-DE").format(
            x.importeNuevo
          ),
          ["Nuevo aporte esp. -desde"]: x.periodoDesdeNuevo,
          ["Nuevo aporte esp. -hasta"]: x.periodoHastaNuevo,
          ["Observaciones"]: x.mensajeError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Actualización aportes esperados");
    }
  }
};
</script>

<style lang="scss" scoped>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
.fontsize {
  font-size: 12px;
}
</style>
